/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Masterplan = {
		// All pages
		'common': {
			init: function() {

				var swiper = new Swiper('.swiper-container', {
			        nextButton: 			'.swiper-button-next',
			        prevButton: 			'.swiper-button-prev',
			        loop: 					true,
			        paginationClickable: 	true,
			        preloadImages: 			false,
        			lazyLoading: 			true,
        			speed: 					0,
        			centeredSlides: 		true,
        			spaceBetween: 			30,
        			onInit: function(swiper) {

        			    var caption = $(swiper.slides[swiper.activeIndex]).find('.swiper-slide__caption').html();

        			    $('.swiper-caption').html(caption);
        			},
        			onSlideChangeStart: function(swiper) {
        			    swiper.params.onInit(swiper);
        			}
		    	});


		    	var tag = document.createElement('script');

	    	    tag.src = "https://www.youtube.com/iframe_api";
	    	    var firstScriptTag = document.getElementsByTagName('script')[0];
	    	      	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	    	    var player;

	    	    window.onYouTubePlayerAPIReady = function() {
	    	    	var video = document.getElementById('overlay-video');

	    	    	player = new YT.Player('overlay-video', {
	    	    	  events: {
	    	    	    // call this function when player is ready to use
	    	    	    'onReady': onPlayerReady
	    	    	  }
	    	    	});
	    	    }

	    	    function onPlayerReady(event) {
	    	      	var closeButton = document.getElementById("overlay__close");
	    	      	closeButton.addEventListener("click", function() {
	    	        	player.stopVideo();
	    	      	});
	    	    }

				var $overlay = $('.overlayblock');

				$('.overlay__close').on('click', function(e) {
		          	$overlay.addClass('overlay__none');				
		        })

				$('.footer__video-open').on('click', function(e) {
		          	$overlay.removeClass('overlay__none');
		        })

		        $(function() {
		            $('.outcomesblock__text-wrapper').matchHeight();
		        });
				
		    }
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Masterplan;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
		// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

  	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
